<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
            <RequireLogin>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="title text-center pa-0 pb-3">Cryptium VPN (alpha)</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3" v-if="!isReadySubscriptionList">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">Loading data...</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-3" v-if="isReadySubscriptionList && !isVpnEnabled">
                            <v-col cols="12">
                                <div class="font-weight-light body-1 text-center mx-5">
                                    <!-- <p><router-link to="/vpn/subscribe">Get access to Cryptium VPN</router-link></p> -->
                                    <p>Currently available to early adopters.</p>
                                    <p><a href="https://cryptium.com/vpn/pricing/">Get access!</a></p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5 mt-5" v-show="isReadySubscriptionList && isVpnEnabled">
                            <v-col cols="12">
                                <div class="font-weight-light body-1 text-center mx-5">
                                    <!-- <p><router-link to="/vpn/subscribe">Get access to Cryptium VPN</router-link></p> -->
                                    <p>Your Cryptium VPN service is ready.</p>
                                    <p>OpenVPN users: click on the configuration link below, and copy the downloaded file into the OpenVPN configuration directory.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5 my-2" v-show="isReadySubscriptionList && isVpnEnabled">
                            <a href="/api/vpn/config?service=openvpn&amp;format=ovpn">Configuration for OpenVPN</a>
                        </v-row>
                        <v-row justify="center" class="mx-5 my-2" v-show="isReadySubscriptionList && isVpnEnabled">
                            <router-link to="/vpn/reset-password">Reset VPN password</router-link>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #555 !important;
}
p a  {
    color: #00f !important;
}
p a:visited  {
    color: #00f !important;
}
p a:hover  {
    color: #00f !important;
    text-decoration: underline;
}
</style>

<script>
import SectionLayout from '@/components/SectionLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import { mapState /* , mapGetters */ } from 'vuex';
import { Client } from '@/client';

const client = new Client();

export default {
    components: {
        SectionLayout,
        RequireLogin,
    },
    data() {
        return {
            name: null,
            alias: null,
            email: null,
            isVpnEnabled: null,
            isReadySubscriptionList: false,
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            // name: (state) => state.user.name,
            // alias: (state) => state.user.alias,
        }),
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        /*
        isVpnEnabled(value, oldValue) {
            if (value && !oldValue) {
                this.enableVpn();
            } else if (!value && oldValue) {
                this.disableVpn();
            }
        },
        */
    },
    methods: {
        async init() {
            console.log(`Account.vue: init user ${JSON.stringify(this.user)}`);
            this.name = this.user.name;
            this.alias = this.user.alias;
            this.email = this.user.email;
            this.loadCustomerSubscriptionList();
        },
        async loadCustomerSubscriptionList() {
            try {
                this.$store.commit('loading', { loadCustomerSubscriptionList: true });
                const response = await client.account.getSubscriptionList();
                if (response && response.data && response.data.length > 0) {
                    // this.subscriptionList = response.data;
                    // look for specific subscription types to show relevant settings
                    const vpnList = response.data.filter((item) => item.info.service === 'vpn');
                    this.isVpnEnabled = vpnList.length > 0;
                }
                console.log(`loadCustomerSubscriptionList response: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log('loadCustomerSubscriptionList failed', err);
            } finally {
                this.$store.commit('loading', { loadCustomerSubscriptionList: false });
                this.isReadySubscriptionList = true;
            }
        },
        /*
        async enableVpn() {
            console.log('account.vue: turn on vpn');
            // const configResponse = await client.vpn.config({ service: 'openvpn' });
            // console.log('account.vue: enableVpn configResponse: %o', configResponse);
            // if (response.content) {
            //     this.vpnConfig = response.content;
            // }
        },
        disableVpn() {
            console.log('account.vue: turn off vpn');
        },
        */
    },
    mounted() {
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
